<template>
  <div class="kr-privacy__wrap">
    <s-checkbox
      v-model="totalPrivacy"
      class="kr-privacy__item"
      :class="{ 'kr-privacy__item-total': from == 'register' }"
    >
      <p class="kr-privacy__item-title">
        {{ language.SHEIN_KEY_PWA_31871 }}
      </p>
      <p
        v-if="from == 'register'"
        class="kr-privacy__item-tips"
      >
        {{ language.SHEIN_KEY_PWA_31872 }}
      </p>
    </s-checkbox>
    <s-checkbox
      v-for="(item, index) in configs"
      :key="index"
      v-model="privacy[item.key]"
      class="kr-privacy__item kr-privacy__item-child"
    >
      <p
        @click.stop=""
        v-html="item.txt"
      ></p>
    </s-checkbox>
  </div>
</template>

<script>
import { Checkbox } from '@shein/sui-mobile'
import { template } from '@shein/common-function'
const { langPath } = gbCommonInfo
export default {
  name: 'KrPrivacy',
  components: { 's-checkbox': Checkbox, },
  props: {
    language: {
      type: Object,
      default: () => {}
    },
    from: { // login || register || quickRegister
      type: String,
      default: 'register'
    }
  },
  data () {
    return {
      privacy: {
        year: false,
        terms: false,
        collection: false,
        notifications: false
      }
    }
  },
  computed: {
    totalPrivacy: {
      get () {
        const requireField = ['year', 'terms', 'collection']
        if (['register', 'quickRegister'].includes(this.from)) requireField.push('notifications')
        return requireField.every(_ => this.privacy[_])
      },
      set (val) {
        Object.keys(this.privacy).map(_ => this.privacy[_] = val)
      }
    },
    configs () {
      const privacies = [
        { key: 'year', txt: this.language.SHEIN_KEY_PWA_31873 },
        { key: 'terms', txt: template(this.linkTxt(399), this.language.SHEIN_KEY_PWA_31874) },
        { key: 'collection', txt: template(this.linkTxt(2610), this.language.SHEIN_KEY_PWA_31875) },
      ]
      if (['register', 'quickRegister'].includes(this.from)) {
        privacies.push({ key: 'notifications', txt: template(this.linkTxt(2615), this.language.SHEIN_KEY_PWA_31876) })
      }
      return privacies
    }
  },
  watch: {
    privacy: {
      handler (val) {
        const requireField = ['year', 'terms', 'collection']
        this.$emit('change-handle', {
          privacy: requireField.every(_ => val[_]),
          subscrip: this.from == 'register' && val.notifications
        })
      },
      deep: true
    }
  },
  methods: {
    // 超链接文本展示
    linkTxt (id) {
      return `<a href="${langPath}/product/article/${id}" class="kr-privacy__more" target="_blank">${this.language.SHEIN_KEY_PWA_31877}</a>`
    }
  }
}
</script>

<style lang="less">
.kr-privacy {
  &__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .kr-privacy__item {
      margin-bottom: .2133rem;
      .S-checkbox__input {
        width: .64rem;
        height: .64rem;
      }
      .S-checkbox__label {
        color: #000000;
      }
      .kr-privacy__item-tips {
        color: #767676;
        font-size: .32rem;
        margin-top: .0533rem;
      }
    }
    .kr-privacy__item-child {
      margin-left: .6967rem;
      .S-checkbox__input {
        width: .5333rem;
        height: .5333rem;
      }
      .S-checkbox__label {
        color: #767676;
        font-size: .32rem;
      }
    }
    .kr-privacy__more {
      color: #2D68A8;
    }
    .kr-privacy__item-total {
      align-items: flex-start;
    }
    .kr-privacy__item-title {
      font-size: .3733rem !important;
    }
  }
}
</style>
